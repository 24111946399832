import { Box, Button, ButtonGroup, Paper, Typography } from '@mui/material'
import React from 'react'
import { FilterTitleBox } from '../../components/layouts/OneViewBox'
import { findObjectKeyByValue, titleCase } from '../../utils/main'
import { BUTTON_STATUS_COLOR_TYPE, BUTTON_TYPE, STATUS, STATUS_COLOR_TYPE } from '../../utils/constants'

const ButtonComponentsUi = ({Total, count, STATUSTypeData, onSubmit, STATUSWiseData, Title ,colorType}) => {
   
    return (<>
        {STATUSTypeData && <Paper  onClick={onSubmit} sx={{backgroundColor: STATUS_COLOR_TYPE(colorType), display: "flex", justifyContent: "center", alignItems: "center", height: "15vh", width: "100%", borderRadius: "8px", cursor: "pointer" ,boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
            <Box>
                <FilterTitleBox>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <Typography variant="h2"  color={!colorType ? "#1D013B":"white"}>
                            {count}
                        </Typography>
                    </Box>
                </FilterTitleBox>   
                <FilterTitleBox>

                    {Total ?<Box mt={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <Typography variant="h4" color={!colorType ? "#1D013B":"white"}>
                            {Total}
                        </Typography>
                    </Box>:<Box mt={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <Typography variant="h4" color={!colorType ? "#1D013B":"white"}>
                            {titleCase(findObjectKeyByValue(STATUSTypeData, BUTTON_TYPE))}
                        </Typography>
                    </Box>}


                </FilterTitleBox>
            </Box>
        </Paper>}

        {Title && 
            <Button onClick={onSubmit} variant={BUTTON_STATUS_COLOR_TYPE(STATUSWiseData)} sx={{height:"6vh"}}>
            <Typography variant="body2" color={STATUSWiseData ? "white" :"primary"}>
                {titleCase(findObjectKeyByValue(Title, STATUS))}
            </Typography>
              <Typography variant="h4" color={STATUSWiseData ?"white" :"primary"}>
                &nbsp; (&nbsp;{count}&nbsp;)
            </Typography>
        </Button>

        }
    </>)
}

export default ButtonComponentsUi

          


             //  sx={{ backgroundColor: STATUS_COLOR_TYPE(STATUSWiseData),border:"1px solid #2D3B67" , height:"6vh",
        //        "&:hover": {
        //     backgroundColor: "#1D013B", 
        //     border: "1px solid #1A2549"  
        //   }
        // }}