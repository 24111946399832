import React, { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import DashboardPageUI from './DashboardPageUI'
import { CreateRepairingTicketApi, getRepairingTicketApi, RepairingTicketStatusCountChangeApi, StatusCountChangeApi } from '../../apis/repairing.api'
import { openModal } from '../../store/actions/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import moment from 'moment';
import MessageDilog from "../../components/texts/MessageDilog"
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, Info } from '@mui/icons-material';
import CustomerInformation from './CustomerInformation';
// import RepairingStatusChange from './RepairingStatusChange';
import { BUTTON_TYPE, STATUS, USER_ROLES } from '../../utils/constants';
import UpdateCreateQueryController from './UpdateCreateQueryController';
import { statusAction, statusActionFilters, statusCountActionFilters } from '../../store/actions/statusAction';

const ActionComponent = memo(({ params, setParams, statusPass }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onEdit = () => {
    dispatch(openModal(<UpdateCreateQueryController id={params._id} statusPass={statusPass}
    />, "md", false, "UpdateQuery"))
  }

  const onInfo = () => {
    dispatch(
      openModal(
        <CustomerInformation params={params} />
        ,
        "md",
        false,
        "CustomerInformation"
      )
    );
  };

  return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
    <Tooltip title={"Customer Information"}>
      <IconButton disabled={loading} size="inherit" onClick={onInfo}>
        <Info color="info" fontSize="inherit" />
      </IconButton>
    </Tooltip>

    {((statusPass == STATUS.YET_TO_RECEIVED) || (statusPass == STATUS.DISPATCH) )  && <IconButton size="inherit" onClick={onEdit}>
      <Edit color="info" fontSize="inherit" />
    </IconButton>}
  </Box>
})

// const UpdateStatusComponent = memo(({ params, setParams }) => {
//   const dispatch = useDispatch()
//   const onEdit = () => {
//     dispatch(openModal(<RepairingStatusChange params={params}
//     />, "sm", false, "UpdateQuery"))
//   }

//   return <Tooltip onClick={onEdit} title={"Repairing status Update"}>
//     <Typography variant="h6"
//       sx={{
//         textDecoration: "underline",
//         marginLeft: "10px",
//         cursor: "pointer"
//       }}>
//       {params?.logs?.length >= 0 ? params?.logs[params?.logs?.length - 1]?.solvedProblem : null}
//     </Typography>
//   </Tooltip>

// })

const DashboardPageControllerLD = () => {
  const dispatch = useDispatch();
  const [LDstatusButton, setLDstatusButton] = useState(BUTTON_TYPE.pending)
  const [STATUSWiseData, setSTATUSWiseData] = useState(STATUS.YET_TO_RECEIVED)
  const [Count, setCount] = useState();

  const statusPass = useRef();
  statusPass.current = STATUSWiseData
  const columns = useMemo(
    () => [

      {
        id: 2,
        fieldName: "createdAt",
        label: "createdAt",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => moment(params.createdAt).format("DD/MM/YYYY")
      },
      {
        id: 1,
        fieldName: "ticket_no",
        label: "Ticket No",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "customer_name",
        label: "Customer Name",
        align: "left",
        sort: true,
        // renderValue: (params, setParams) => params.cName,
      },
      {
        id: 4,
        fieldName: "box_id",
        label: "Box Id",
        align: "left",
        sort: true,
        // renderValue: (params, setParams) => params.rawMaterials.min_of_quantity,
      },
      {
        id: 5,
        fieldName: "customer_phone",
        label: "Phone",
        align: "left",
        sort: true,
        // renderValue: (params, setParams) => params.rawMaterials.min_of_quantity,
      },

      // {
      //   id: 6,
      //   fieldName: "repairing",
      //   label: "Repairing status",
      //   align: "left",
      //   sort: true,
      //   renderValue: (params, setParams) =>
      //     <UpdateStatusComponent
      //       params={params}
      //       setParams={setParams}
      //     />
      // },
      {
        id: 2,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            statusPass={statusPass.current}

          />
        ),
      },
    ],
    [dispatch]
  );

  
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['customer_name'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });

  const [loading, setLoading] = useState(false);
  const statusData = useSelector((state) => state)
  const list = useRef();
  list.current = statusData.statusReducer.status_data
 
  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getRepairingTicketApi({ ...filters, status_role: STATUSWiseData }),
        (response) => {
          dispatch(statusAction(response));
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };



  const fetchCountData = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await RepairingTicketStatusCountChangeApi({  role:USER_ROLES.LD }),
        async (response) => {
          setCount({ response });

          setLoading(false);
        },
        (err) => {
          setCount({ err });
          setLoading(false);
        }
      )
    );
  };

  const [CountStatus, setCountStatus] = useState()

const fetchCountDataAll = () => {
  setLoading(true);
  dispatch(
    callApiAction(
      async () => await StatusCountChangeApi({ role: USER_ROLES.LD }),
      async (response) => {
        setCountStatus({ response });

        setLoading(false);
      },
      (err) => {
        setCountStatus({ err });
        setLoading(false);
      }
    )
  );
};


// useEffect(() => {
//   if (!statusData.statusReducer.status_data || statusData.statusReducer.status_data?.total === list.current?.total) {
//     fetchList();
//     fetchCountDataAll();
//     fetchCountData();
//   }
// }, [STATUSWiseData, statusData.statusReducer.filters,filters])


useEffect(() => {
  if ( JSON.stringify(filters) === JSON.stringify(statusData.statusReducer.filters) ) {
    fetchList();
  }
}, [STATUSWiseData, statusData.statusReducer.filters])

useEffect(() => {
  dispatch(statusActionFilters(filters))
}, [statusData.statusReducer.filters,filters])

useEffect(() => {
  dispatch(statusCountActionFilters(1));
}, [statusData.statusReducer.Count])

useEffect(() => {
  if(statusData.statusReducer.Count !== -1){
    fetchCountDataAll();
  }
}, [statusData.statusReducer.Count])

  return (
    <DashboardPageUI
    CountStatus={CountStatus}
      columns={columns}
      list={list.current}
      loading={loading}
      // setList={setList}
      filters={filters}
      setFilters={setFilters}
      LDstatusButton={LDstatusButton}
      setLDstatusButton={setLDstatusButton}
      setSTATUSWiseData={setSTATUSWiseData}
      STATUSWiseData={STATUSWiseData}
      Count={Count}
    />
  )
}

export default DashboardPageControllerLD