import CustomInput from '../../components/inputs/CustomInputs';
import { Box, Button, Grid, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
// import { CenteredBox } from '../../components/layouts/OneViewBox';
import SubmitButton from '../../components/button/SubmitButton';
// import { Link } from 'react-router-dom';
import MainUi from './MainUi';
import { center } from '../../assets/css/theme/common';
// import bgimage from "../../assets/images/frame.jpg"
import RMSLOGO from "../../assets/images/logoRms.svg"
import CURVESTYLE from "../../assets/images/Curvestyle.svg"
import OneViewBox from '../../components/layouts/OneViewBox';
import BgGradient from '../../components/layouts/auth/BgGradient';
import GradientText from '../../components/texts/GradientText';
import CustomContainer from '../../components/layouts/CustomContainer';
import AuthLeftContainer from '../../components/layouts/auth/AuthLeftContainer';
import AuthRighttContainer from '../../components/layouts/auth/AuthRightContainer';
import SignInButton from '../../components/button/SignInButton';

const SignInUI = ({ state, setState, onSubmit, loading }) => {
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))

    return (

        <>
            <OneViewBox sx={{ overflow: "hidden" }}  >
                <BgGradient left={true} />
                <BgGradient />
               { isSmallScreen && <Box  mt={5} mb={5}></Box>}
               {!isSmallScreen && <GradientText variant='h1' sx={{ ...center }} mt={5} mb={5}>WEHEAR RMS</GradientText >}
                <CustomContainer sx={{ marginBottom: "100px" }}>
                    <Grid container spacing={2} sx={{ display: "flex" }}>
                        <Grid item xs={12} md={6}>
                            <AuthLeftContainer />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <AuthRighttContainer >
                                <Box component={"form"} width={"100%"} onSubmit={onSubmit}>

                                    <Typography variant="h2" mb={2}>
                                        Log In
                                    </Typography>
                                    <Typography variant="h4" color={"red"} mb={1}>
                                        {state.err}
                                    </Typography>
                                    <Box  >
                                        <Box mb={3}     >
                                            
                                        <CustomInput
                                            disabled={loading}
                                            value={state.email}
                                            onChange={(e) => setState({ ...state, err: '', email: e.target.value })}
                                            type="text"
                                            label={"Email"}
                                            />
                                            </Box>
                                        <CustomInput
                                            disabled={loading}
                                            value={state.password}
                                            onChange={(e) => setState({ ...state, err: '', password: e.target.value })}
                                            type="password"
                                            label={"Password"}
                                        />

                                    </Box>
                                    <Box mt={4}>
                                        <SignInButton loading={loading} disabled={loading} type='submit' title='Sign In' />
                                    </Box>

                                </Box>
                            </AuthRighttContainer>
                        </Grid>
                    </Grid>




                </CustomContainer>
            </OneViewBox>


        </>
    )
}
export default SignInUI




// const SignInUI = ({ state, setState, onSubmit, loading }) => {
//   return (
//     <>
//       <Box sx={{ height: "100%",overflow:"hidden", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", background: `linear-gradient(270deg, rgba(255, 234, 224,0.1)60%, rgba(29, 1, 59,1) 30%) `}}>
//         <Grid md={12} xs={12} sm={12} container>
//           <Grid md={5.9} xs={6} sm={6} container>
//           <Grid md={10} p={6} xs={10} sm={10} display={"flex"} width={"80%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
//               <img src={RMSLOGO} alt='RMSLOGO' style={{ minHeight: "8vh", maxHeight: "6vh" ,maxWidth:"350px",minWidth:"150px"}}  />
//             </Grid>
//           </Grid>
//           <Grid md={1} xs={1} sm={1} >
//               <Box sx={{ rotate: "270deg", height: "150%" }}>
//                 <img src={CURVESTYLE} alt='curvestyle' style={{ minHeight: "10vh", maxHeight: "200px" }}/>
//               </Box>
//             </Grid>
//           <Grid md={4} xs={4} sm={4}>
//             <Box p={3}>
//               <Box container justifyContent={center}>
//                 <Typography
//                   component="h1"
//                   variant="h1"
//                   sx={{ color: "#1d012B" }}>
//                   Login
//                 </Typography>
//               </Box>
//               <CustomInput
//                 margin="dense"
//                 disabled={loading}
//                 value={state.email}
//                 onChange={(e) => setState({ ...state, err: ' ', email: e.target.value })}
//                 type="text"
//                 label={"Email"}
//               />
//               <Box mt={3} />
//               <CustomInput
//                 margin="dense"
//                 disabled={loading}
//                 value={state.password}
//                 onChange={(e) => setState({ ...state, err: '', password: e.target.value })}
//                 type="password"
//                 label={"Password"}
//               />
//               <Box display={"flex"} flexDirection={"column"}>
//                 <Box mt={4} onClick={onSubmit}>
//                   <SubmitButton variant="contained" loading={loading} disabled={loading} type='submit' title='Login' />
//                 </Box>
//               </Box>
//             </Box>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   )
// }
// export default SignInUI
