import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

import MessageDilog from "../../components/texts/MessageDilog";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import ProblemListUi from "./ProblemListUi";
import { DeleteProblemApi, FetchProblemApi } from "../../apis/problem.api";
import CreateProblemController from "./CreateProblemController";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();
  const modalkey = "delete";
  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateProblemController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        false,
        "update"
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault()
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(closeModal("messagedialogdelete")); 
          dispatch(callSnackBar(params.name + " Deleted Successfully", SNACK_BAR_VARIETNS.suceess))

        },
        (err) => {
          setLoading(false);
        }
      )
      
    );

   
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={(e)=>deleteFun(e)}
          title="Alert!"
          modalId="messagedialogdelete"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm",
        false,
        "messagedialogdelete"
      )
    );
  };

  // const onDelete = () => {
  //     dispatch
  // }

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
      <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton>
    </Box>
  );
});

const ProblemListController = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state)
  const title = "Problem ";
  const deleteApi = DeleteProblemApi;
  const [loading, setLoading] = useState(false);
const [list, setlist] = useState()
  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "name",
        label: "Problem Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <Typography textTransform="capitalize">{params.name}</Typography>
        ),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "",
    sortDirection: -1,
  });


  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({ ...filters }),
        (response) => {
          setLoading(false);
          setlist(response);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(()=>{
    fetchList();
  },[filters])

  return (
    <>
      <ProblemListUi
      setlist={setlist}
      list={list}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        columns={columns}
      />
    </>
  );
};
export default ProblemListController;
