import { actions } from "../../utils/constants"


const initialState = {

    status_data: {},
    loading: false,
    filters: {},
    Count:-1

}

const statusReducer = (state = { initialState }, action) => {
    switch (action.type) {
        case actions.FETCH_DATA_STATUS_DATA:
            return { ...state, status_data: action.value }
        case actions.FETCH_DATA_STATUS_Filters:
            return { ...state, filters: action.filters }
        case actions.FETCH_DATA_COUNT_STATUS_Filters:
            return { ...state, Count: action.Count }
        default:
            return { ...state }
    }
}

export default statusReducer