import { useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import ResetTokenUi from "./ResetTokenUi";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/actions/modalAction";
// import { SNACK_BAR_VARIETNS } from "../../utils/constants/main";
import { resetPasswordUserApi, resetTokenApi } from "../../apis/auth.api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { callApiAction } from "../../store/actions/commonAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const ResetTokenController = () => {
  const dispatch = useDispatch();
  const validate = useValidate();

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    current_pass: "",
    err: "",
    new_pass: "",
  });

  const validationSchema = useMemo(
    () => [
      {
        required: true,
        value: state.new_pass,
        field: "New Password",
      },
      {
        required: true,
        value: state.current_pass,
        field: "Old Password",
      },
    ],
    [state]
  );
console.log("reset passwor",state)
  const onSubmit = async (e) => {
    e.preventDefault();

    const validationResponse = validate(validationSchema);

    if (validationResponse === true) {
      setLoading(true);

      setLoading(true);
      dispatch(
        callApiAction(
          async () => await resetPasswordUserApi(state),
          async (response) => {

            setLoading(false);
            dispatch(
              callSnackBar("Password Reset successfully", {
                variant: SNACK_BAR_VARIETNS.suceess,
              })
            );
            dispatch(closeModal());
          },
          (err) => {
            setState({ ...state, err });
            setLoading(false);
          }
        )
      );
    } else {
      setState({ ...state, err: validationResponse });
    }
  };

  return (
    <ResetTokenUi
      state={state}
      setState={setState}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};
export default ResetTokenController;
