import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, ListItem, TextField, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, STATUS, USER_ROLES, PAYMENT_METHOD } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi, getBoxnotallocatedboxIdApi } from "../../apis/boxid.api";
import { CheckBox } from "@mui/icons-material";


const UpdateCreateQueryUi = ({ statusPass, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [isChecked, setIsChecked] = useState(false);
    // const arrr=[]
    const user = useSelector(state => state.user)


    return (
        <>
            <CustomDialog
                id={`UpdateQuery`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={` Update ${title}`}
                closeText="Close"
                confirmText={`Update`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    defaultVal={
                                        fields.product_id?._id ? {
                                            _id: fields.product_id?._id,
                                            name: fields.product_id?.name,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        console.log("first changedVal", changedVal)
                                        setFields({ ...fields, product_id: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>
                            <CustomInput
                                // autoFocus={true}
                                disabled={user.data.role !== USER_ROLES.ADMIN}

                                value={fields.box_id}
                                onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                type="text"
                                label={" Box Id.*"}
                            />
                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />

                            </Box>

                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}


                                    defaultVal={
                                        fields.source_id?._id ? {
                                            _id: fields.source_id?._id,
                                            title: fields.source_id?.title,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <Box gap={3} mt={4} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Autocomplete
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={listProblem?.filter(problem => fields.problems.includes(problem.name)) || []}
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />
                                {fields.other && <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />}
                            </Box>
                            <Box mt={2}>

                                {statusPass === STATUS.YET_TO_RECEIVED && <FormControl >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            status: [
                                                                // ...fields.status,
                                                                {
                                                                    status_role: STATUS.START_EVALUTION,
                                                                    role: USER_ROLES.CRM_TEAM
                                                                },
                                                                {
                                                                    status_role: STATUS.SEND_TO_CRM,
                                                                    role: USER_ROLES.LD
                                                                }
                                                            ]
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Device Arrived"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>}


                            </Box>

                            {statusPass === STATUS.DISPATCH && <>
                                {fields.device_is === "Replace" && <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                    <AsyncDropDown
                                        defaultVal={
                                            fields.new_box_id ? {

                                                _id: fields._id,
                                                new_box_id: fields.new_box_id,
                                            }
                                                : null
                                        }
                                        lazyFun={async (para) => await getBoxnotallocatedboxIdApi({ ...para, allStatus: true, searchable: ["boxId", "sold.cphone"] })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.boxId}</ListItem >
                                        }}
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, new_box_id: changedVal ? changedVal.boxId : null })
                                        }}
                                        titleKey={'boxId'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder={"New Box Id*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}

                                <CustomInput
                                    disabled={loading}
                                    value={fields.tracking_id}
                                    onChange={(e) => setFields({ ...fields, err: '', tracking_id: e.target.value })}
                                    type="text"
                                    label={" Tracking Id.*"}
                                />

                                <Box mt={1}>
                                    <FormControl >
                                        <FormGroup component="fieldset" row>
                                            {<FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isChecked}
                                                        onChange={(e) => {
                                                            setIsChecked(e.target.checked);
                                                            setFields({
                                                                ...fields,
                                                                err: "",
                                                                status: fields.device_is === "Replace" ? [
                                                                    {
                                                                        status_role: STATUS.COMPLETED,
                                                                        role: USER_ROLES.LD
                                                                    },
                                                                    {
                                                                        status_role: STATUS.COMPLETED,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    },
                                                                    // {
                                                                    //     status_role: STATUS.REPAIR_REPLACEMENT,
                                                                    //     role: USER_ROLES.LD
                                                                    // },
                                                                    // {
                                                                    //     status_role: STATUS.REPAIR_REPLACEMENT,
                                                                    //     role: USER_ROLES.CRM_TEAM
                                                                    // }, 
                                                                    {
                                                                        status_role: STATUS.REPLACEMENT,
                                                                        role: USER_ROLES.REPAIR_TEAM
                                                                    }
                                                                ]
                                                                    :
                                                                    [
                                                                        {
                                                                            status_role: STATUS.COMPLETED,
                                                                            role: USER_ROLES.LD
                                                                        },
                                                                        {
                                                                            status_role: STATUS.COMPLETED,
                                                                            role: USER_ROLES.CRM_TEAM
                                                                        },
                                                                        {
                                                                            status_role: STATUS.REPAIR_COMPLETED,
                                                                            role: USER_ROLES.REPAIR_TEAM
                                                                        }
                                                                        // {
                                                                        //     status_role: STATUS.REPAIR_COMPLETE,
                                                                        //     role: USER_ROLES.LD
                                                                        // },
                                                                        // {
                                                                        //     status_role: STATUS.REPAIR_COMPLETE,
                                                                        //     role: USER_ROLES.CRM_TEAM
                                                                        // },
                                                                    ]
                                                            });
                                                        }}
                                                        name="Shipped"
                                                    />
                                                }
                                                label=" Device Send To The Customer"
                                                labelPlacement="end"
                                            />}

                                        </FormGroup>
                                    </FormControl>
                                </Box></>}




                        </Box>
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(UpdateCreateQueryUi);



// {statusPass === STATUS.REPLACEMENT && <Box>
//     <FormControlLabel
//         control={
//             <Checkbox
//                 checked={fields.device_is === "Replace"}
//                 name="Replace"
//                 disabled={true}
//             />
//         }
//         label="Replace"
//         labelPlacement="end"
//     />
//     {fields.device_is === "Replace" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
//         <CustomInput
//             autoFocus={true}
//             disabled={true}
//             value={fields?.replace_remarks}
//             onChange={(e) => setFields({ ...fields, err: '', replace_remarks: e.target.value })}
//             type="text"
//             label={"Replace Remarks*"}
//         />
//     </Box>}
//     <CustomInput
//         // autoFocus={true}
//         disabled={true}
//         value={fields.box_id}
//         onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
//         type="text"
//         label={" Box Id.*"}
//     />
//     <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
//         <AsyncDropDown
//             defaultVal={
//                 fields.new_box_id ? {

//                     _id: fields._id,
//                     new_box_id: fields.new_box_id,
//                 }
//                     : null
//             }
//             lazyFun={async (para) => await getBoxnotallocatedboxIdApi({ ...para, allStatus: true, searchable: ["boxId", "sold.cphone"] })}
//             OptionComponent={({ option, ...rest }) => {
//                 return <ListItem {...rest}>{option.boxId}</ListItem >
//             }}
//             onChange={async (changedVal) => {
//                 setFields({ ...fields, new_box_id: changedVal ? changedVal.boxId : null })
//             }}
//             titleKey={'boxId'}
//             valueKey={"_id"}
//             InputComponent={(params) => <StyledSearchBar placeholder={"New Box Id*"} {...params} margin="none" />}
//         />
//     </PaddingBoxInDesktop>
//     <CustomInput
//         // autoFocus={true}
//         disabled={loading}
//         value={fields.tracking_id}
//         onChange={(e) => setFields({ ...fields, err: '', tracking_id: e.target.value })}
//         type="text"
//         label={" Tracking Id.*"}
//     />
//     <Box mt={5}>
//         <FormControl >
//             {/* <FormLabel component="legend">Send To Shipped</FormLabel> */}
//             <FormGroup component="fieldset" row>
//                 {<FormControlLabel
//                     control={
//                         <Checkbox
//                             checked={isChecked}
//                             onChange={(e) => {
//                                 setIsChecked(e.target.checked);
//                                 setFields({
//                                     ...fields,
//                                     err: "",
//                                     status: [
//                                         {
//                                             status_role: STATUS.SHIPPED,
//                                             role: USER_ROLES.LD
//                                         },
//                                         {
//                                             status_role: STATUS.SHIPPED,
//                                             role: USER_ROLES.CRM_TEAM
//                                         },
//                                         {
//                                             status_role: STATUS.REPAIR_REPLACEMENT,
//                                             role: USER_ROLES.REPAIR_TEAM
//                                         }, {
//                                             status_role: STATUS.LOGISTIC_DEPARTMENT,
//                                             role: USER_ROLES.CRM_TEAM
//                                         }
//                                     ]
//                                 });
//                             }}
//                             name="Shipped"
//                         />
//                     }
//                     label="Send To Shipped"
//                     labelPlacement="end"
//                 />}

//             </FormGroup>
//         </FormControl>
//     </Box>
// </Box>}




// {statusPass === STATUS.SHIPPED && <> <CustomInput
//     // autoFocus={true}
//     disabled={loading}
//     value={fields.tracking_id}
//     onChange={(e) => setFields({ ...fields, err: '', tracking_id: e.target.value })}
//     type="text"
//     label={" Tracking Id.*"}
// />
//     <Box mt={5}>
//         <FormControl >
//             <FormLabel component="legend">Send To Completed</FormLabel>
//             <FormGroup component="fieldset" row>
//                 <FormControlLabel
//                     control={
//                         <Checkbox
//                             checked={isChecked}
//                             onChange={(e) => {
//                                 const checked = e.target.checked;
//                                 setIsChecked(checked);

//                                 const newStatus = fields.replace_remarks
//                                     ? [
//                                         {
//                                             status_role: STATUS.COMPLETED,
//                                             role: USER_ROLES.LD
//                                         },
//                                         {
//                                             status_role: STATUS.COMPLETED,
//                                             role: USER_ROLES.CRM_TEAM
//                                         },
//                                         {
//                                             status_role: STATUS.REPAIR_REPLACEMENT,
//                                             role: USER_ROLES.REPAIR_TEAM
//                                         }
//                                     ]
//                                     : [
//                                         {
//                                             status_role: STATUS.COMPLETED,
//                                             role: USER_ROLES.LD
//                                         },
//                                         {
//                                             status_role: STATUS.COMPLETED,
//                                             role: USER_ROLES.CRM_TEAM
//                                         },
//                                         {
//                                             status_role: STATUS.REPAIR_COMPLETED,
//                                             role: USER_ROLES.REPAIR_TEAM
//                                         }
//                                     ];

//                                 setFields({
//                                     ...fields,
//                                     err: "",
//                                     status: newStatus,
//                                 });
//                             }}
//                             name="Completed"
//                         />
//                     }
//                     label="Completed"
//                     labelPlacement="end"
//                 />
//             </FormGroup>

//         </FormControl>
//     </Box></>}


// {statusPass === STATUS.WAITING_FOR_PAYMENT && <Box mt={2}>
//                                     <FormControl fullWidth>
//                                         <InputLabel>Pending Payment</InputLabel>
//                                         <Select
//                                             value={fields.repairing_amount_status}
//                                             label="Pending Payment"
//                                             onChange={(e) => {
//                                                 const selectedStatus = e.target.value;
//                                                 const status =
//                                                     (fields.repairing_amount_status !== PAYMENT_METHOD.PAYMENT_RECEIVED
//                                                         ?
//                                                         [{
//                                                             status_role: STATUS.SEND_TO_REPAIR,
//                                                             role: USER_ROLES.REPAIR_TEAM
//                                                         },
//                                                         {
//                                                             status_role: STATUS.REPAIR_TEAM,
//                                                             role: USER_ROLES.CRM_TEAM
//                                                         }, {
//                                                             status_role: STATUS.REPAIR_TEAM,
//                                                             role: USER_ROLES.LD
//                                                         }]
//                                                         :
//                                                         [{
//                                                             status_role: STATUS.SHIPPED,
//                                                             role: USER_ROLES.CRM_TEAM
//                                                         },
//                                                         {
//                                                             status_role: STATUS.SHIPPED,
//                                                             role: USER_ROLES.LD
//                                                         }, {
//                                                             status_role: STATUS.LOGISTIC_DEPARTMENT,
//                                                             role: USER_ROLES.CRM_TEAM
//                                                         }])
//                                                 setFields({
//                                                     ...fields,
//                                                     err: '',
//                                                     repairing_amount_status: selectedStatus,
//                                                     status
//                                                 });
//                                             }}
//                                         >
//                                             <MenuItem value={PAYMENT_METHOD.PAYMENT_RECEIVED}>{titleCase(PAYMENT_METHOD.PAYMENT_RECEIVED)}</MenuItem>
//                                             <MenuItem value={PAYMENT_METHOD.CANCEL_PAYMENT}>{titleCase(PAYMENT_METHOD.CANCEL_PAYMENT)}</MenuItem>
//                                         </Select>
//                                     </FormControl>
//                                 </Box>
//                                 }


{/* <Box mt={4} width={"100%"}>
                                <Box>
                                    <Typography variant="h3">
                                        Solved In :
                                    </Typography>
                                </Box>
                                <Box mt={4} gap={2} display={"flex"} justifyContent={"space-around"} flexDirection={"row"} >
                                    <Box>
                                        <Button
                                            variant={fields.solved_in === "call" || variant1 === SOLUTIONS.CALL ? "contained" : "outlined"}
                                            onClick={(e) => {
                                                setVariant(SOLUTIONS.CALL);
                                                setFields({ ...fields, err: '', solved_in: SOLUTIONS.CALL })
                                            }
                                            }
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.Videao_Call);
                                            setFields({ ...fields, err: '', solved_in: SOLUTIONS.Videao_Call })
                                        }}
                                            variant={fields.solved_in === "vc" || variant1 === SOLUTIONS.Videao_Call ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Video Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.REPAIR);
                                            setFields({ ...fields, err: '', solved_in: SOLUTIONS.REPAIR })
                                        }}
                                            variant={fields.solved_in === "repairing" || variant1 === SOLUTIONS.REPAIR ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Repair</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box> */}


{/* {variant1 === SOLUTIONS.REPAIR && <PaddingBoxInDesktop mt={4} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                    <AsyncDropDown
                                        lazyFun={async (para) => await getTicketProblemsFetchApi({ ...para, allStatus: true })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.name}</ListItem >
                                        }}
                                        defaultVal={
                                            fields.status ? {
                                                _id: fields.status._id,
                                                name: fields.status.name,
                                            } : null
                                        }
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, status: changedVal ? changedVal._id : null, })
                                        }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder={"Repairing Ticket Problems*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}
                                {variant1 === SOLUTIONS.REPAIR &&
                                    <Box mt={3}>
                                        <Autocomplete

                                            options={SOLVED_STATUS}
                                            // defaultValue={
                                            //     fields.disibility_type
                                            //         ? fields.disibility_type?.map((item) =>
                                            //             DISIBILITY_TYPE.find((e) => item == e.value)
                                            //         )
                                            //         : []
                                            // }
                                            onChange={(event, newValue) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    solvedProblem: newValue.label,
                                                });
                                            }}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <li key={key} {...optionProps}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Repairing status*" placeholder="" />
                                            )}
                                        />
                                    </Box>}

                                {variant1 === SOLUTIONS.REPAIR && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                </Box>} */}