import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import snackBarReducer from "./snackbarReducer";
import modalReducer from "./modalReducer";
import notificationReducer from "./notificationReducer";
import statusReducer from "./statusReducer";

const rootReducer = combineReducers({
    user: userReducer,
    snackBar: snackBarReducer,
    modal: modalReducer,
    notifications: notificationReducer,
    statusReducer:statusReducer
});
export default rootReducer;