import { Box } from "@mui/material"
import logo from '../../../assets/images/rmslog.svg';
import { center } from "../../../assets/css/theme/common.js";
import ImageComponent from "../../ImageComponent.jsx";

const outerBoxStyle = (theme) => {

    return {
        display: "flex",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100%",
        borderRight: `3px solid rgba(29, 1, 59, 0.5)`,
        [theme.breakpoints.down('md')]: {
            borderRight: `none`,
            width:"50%",
            margin:"auto"
        },
        
    }
}
const imageStyle =  {
    width: '60%',
    height: 'auto',
    maxHeight: '100%',
    objectFit: 'contain',
    maxWidth: '500px',
}
const AuthLeftContainer = () => {
    return (
        <Box sx={outerBoxStyle}>

            <ImageComponent
                sx={imageStyle}
                 src={logo} alt=""
                  />

        </Box>
    )
}
export default AuthLeftContainer