import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";



export const CreateRepairingTicketApi = async () => {
    const callResponse = await axios({
        url: endpoints.RepairingTicketGenrator,
        method: "post",
        headers: getHeaders()
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const RepairingTicketCreateApi = async data => {
    const callResponse = await axios({
      url: endpoints.RepairingTicketCreate,
      method: "post",
      headers: getHeaders(),
      data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };

  export const getRepairingTicketApi = async params => {
    const callResponse = await axios({
        url: endpoints.RepairingTicketFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getBoxIDFetchApi = async params => {
  const callResponse = await axios({
      url: endpoints.BoxIDFetch,
      method: "get",
      headers: getHeaders(),
      params,
  })
      .then(response => response.data)
      .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
};

export const RepairingTicketStatusCountChangeApi = async params => {
  const callResponse = await axios({
      url: endpoints.RepairingTicketStatusCount,
      method: "get",
      headers: getHeaders(),
      params,
  })
      .then(response => response.data)
      .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
}

export const StatusCountChangeApi = async params => {
  const callResponse = await axios({
      url: endpoints.StatusCount,
      method: "get",
      headers: getHeaders(),
      params,
  })
      .then(response => response.data)
      .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

  return callResponse;
}

export const RepairingUpdateTicketApi = async data => {
  const callResponse = await axios({
    url: endpoints.RepairingTicketStatusUpdate,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getRepairingTicketStatusByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.RepairingTicketStatusById,
    method: "GET",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

// export const RepairingTicketStatusChangeApi = async data => {
//   const callResponse = await axios({
//     url: endpoints.RepairingTicketStatusChange,
//     method: "put",
//     headers: getHeaders(),
//     data,
//   })
//     .then(response => response.data)
//     .catch(err => err.response.data);

//   return callResponse;
// };

// export const getTicketProblemsFetchApi = async params => {
//   const callResponse = await axios({
//       url: endpoints.TicketProblemsFetch,
//       method: "get",
//       headers: getHeaders(),
//       params,
//   })
//       .then(response => response.data)
//       .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

//   return callResponse;
// };
