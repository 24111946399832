import { Autocomplete, CircularProgress, MenuItem } from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'

// import CustomInput from "../../componets/inputs/CustomInput"
import { USER_ROLES } from "../../utils/constants"
// import { findNameByRole, titleCase } from "../../utils/helper"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CustomInput from "../../components/inputs/CustomInputs"
import { findNameByRole, titleCase } from "../../utils/main"



const CreateUserUi = ({ title, isInstaller, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)

    let allowedRoles = [...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]

    if (user.data.role === USER_ROLES.partner_admin) {
        allowedRoles = []
        Object.keys(USER_ROLES).forEach((key) => {
            if (USER_ROLES[key] != USER_ROLES.admin)
                allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] })
        })
    }


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Name*"}

                    />

                    {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="text"
                        label={"Email*"}

                    />}
                    {
                        <CustomInput
                            disabled={loading}
                            value={fields.phone}
                            onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
                            type="tel"
                            label={"Phone No*"}

                        />
                    }
                    {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.password}
                        onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}

                        type="password"
                        label={"Password*"}

                    />}


                    <Autocomplete

                        // key={loading}
                        disableClearable
                        options={allowedRoles}
                        value={findNameByRole(fields.role)}
                        // id="user-role-id"
                        // name="user-role-id"
                        onChange={(e, newVal) => {
                            setFields({ ...fields, role: newVal ? newVal._id : null, parent_id: null })
                        }}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" margin="dense" />}
                    />

                    {/* {!isInstaller && user.data.role != USER_ROLES.distributor && <Autocomplete
                        // disablePortal
                        disableClearable
                        options={allowedRoles}
                        value={findNameByRole(fields.role)}
                        // id="user-role-id"
                        // name="user-role-id"
                        onChange={(e, newVal) => {
                            setFields({ ...fields, role: newVal ? newVal._id : null })
                        }}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" />}
                    />}

                    {!isInstaller && user.data.role == USER_ROLES.distributor &&
                        <Autocomplete
                            // disablePortal
                            disableClearable
                            options={allowedRoles}
                            readOnly
                            value={findNameByRole(fields.role)}
                            // id="user-role-id"
                            // name="user-role-id"
                            onChange={(e, newVal) => {
                                setFields({ ...fields, role: newVal ? newVal._id : null })
                            }}

                            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                            renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" />}
                        />
                    } */}

                </>}
        </CustomDialog>
    </>
}
export default memo(CreateUserUi)
