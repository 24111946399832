import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const getProdutApi = async params => {
    const callResponse = await axios({
        url: endpoints.produtFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};