import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, ListItem, TextField, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, USER_ROLES, STATUS } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi } from "../../apis/boxid.api";


const CreateQueryUi = ({ listProblem, setArrr, arrr, title, modalKey, isUpdate, fields, setFields, loading, onSubmit, setVariant, variant1 }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    console.log("fieldsss", fields)

    return (
        <>
            <CustomDialog
                id={`${isUpdate ? "Query" : "Query"}`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={`${isUpdate ? "Update" : "Create"} ${title}`}
                closeText="Close"
                confirmText={`${isUpdate ? "Update" : "Create"}`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown

                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, product_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>
                            <AsyncDropDown
                                defaultVal={
                                    fields.boxId ? {

                                        _id: fields._id,
                                        boxId: fields.boxId,
                                    }
                                        : null
                                }
                                lazyFun={async (para) => await getBoxIdApi({ ...para, allStatus: true, searchable: ["boxId", "sold.cphone"] })}
                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.boxId}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFields({ ...fields, box_id: changedVal ? changedVal.boxId : null, _id: changedVal ? changedVal._id : null, customer_phone: changedVal ? changedVal.sold.cphone : null, customer_email: changedVal ? changedVal.sold.cemail : null, customer_name: changedVal ? changedVal.sold.cname : null, customer_address: changedVal ? changedVal.sold.caddress : null })
                                }}
                                titleKey={'boxId'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Box Id*"} {...params} margin="none" />}
                            />
                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />

                                {/* <AsyncDropDown
                                    defaultVal={
                                        fields.customer_phone ? {

                                            _id: fields._id,
                                            cphone: fields.customer_phone,
                                        }
                                            : null
                                    }
                                    lazyFun={async (para) => await getBoxIdApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.sold.cphone}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, box_id: changedVal ? changedVal.boxId : null, _id: changedVal ? changedVal._id : null,customer_phone:changedVal ? changedVal.sold.cphone : null,customer_email:changedVal ? changedVal.sold.cemail : null,customer_name:changedVal ? changedVal.sold.cname : null, })
                                    }}
                                    titleKey={'cphone'}
                                    valueKey={"sold.soldBy"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Phone No.*"} {...params} margin="none" />}
                                /> */}


                            </Box>

                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    //  defaultVal={
                                    //     fields.status ? {
                                    //         _id: fields.status,
                                    //         name: fields._id,
                                    //     } : null
                                    // }
                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                <Autocomplete
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />
                                {fields?.problems?.map((item) =>
                                (item === "Other" && <CustomInput
                                    disabled={loading}
                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />
                                ))}
                            </Box>

                            <Box mt={4} width={"100%"}>
                                <Box>
                                    <Typography variant="h3">
                                        Solved In :
                                    </Typography>
                                </Box>
                                <Box mt={4} gap={2} display={"flex"} justifyContent={"space-around"} flexDirection={"row"} >
                                    <Box>
                                        <Button
                                            variant={fields.solved_in === "call" || variant1 === SOLUTIONS.CALL ? "contained" : "outlined"}
                                            onClick={(e) => {
                                                setVariant(SOLUTIONS.CALL);
                                                setFields({
                                                    ...fields, err: '', solved_in: SOLUTIONS.CALL, status: [
                                                        // ...fields.status,
                                                        {
                                                            status_role: STATUS.COMPLETED,
                                                            role: USER_ROLES.CRM_TEAM
                                                        },
                                                        {
                                                            status_role: STATUS.COMPLETED,
                                                            role: USER_ROLES.LD
                                                        }
                                                    ]
                                                })
                                            }
                                            }
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.Videao_Call);
                                            setFields({
                                                ...fields, err: '', solved_in: SOLUTIONS.Videao_Call, status: [
                                                    // ...fields.status,
                                                    {
                                                        status_role: STATUS.COMPLETED,
                                                        role: USER_ROLES.CRM_TEAM
                                                    },
                                                    {
                                                        status_role: STATUS.COMPLETED,
                                                        role: USER_ROLES.LD
                                                    }
                                                ]
                                            })
                                        }}
                                            variant={fields.solved_in === "vc" || variant1 === SOLUTIONS.Videao_Call ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Video Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.REPAIR);
                                            setFields({
                                                ...fields, err: '', solved_in: SOLUTIONS.REPAIR, status: [
                                                    // ...fields.status,
                                                    {
                                                        status_role: STATUS.YET_TO_RECEIVED,
                                                        role: USER_ROLES.CRM_TEAM
                                                    }, {
                                                        status_role: STATUS.YET_TO_RECEIVED,
                                                        role: USER_ROLES.LD
                                                    }
                                                ]
                                            })
                                        }}
                                            variant={fields.solved_in === "repairing" || variant1 === SOLUTIONS.REPAIR ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Repair</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(CreateQueryUi);



{/* {variant1 === SOLUTIONS.REPAIR && <PaddingBoxInDesktop mt={4} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                    <AsyncDropDown
                                        lazyFun={async (para) => await getTicketProblemsFetchApi({ ...para, allStatus: true })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.name}</ListItem >
                                        }}
                                        defaultVal={
                                            fields.status ? {
                                                _id: fields.status._id,
                                                name: fields.status.name,
                                            } : null
                                        }
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, status: changedVal ? changedVal._id : null, })
                                        }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder={"Repairing Ticket Problems*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}
                                {variant1 === SOLUTIONS.REPAIR &&
                                    <Box mt={3}>
                                        <Autocomplete

                                            options={SOLVED_STATUS}
                                            // defaultValue={
                                            //     fields.disibility_type
                                            //         ? fields.disibility_type?.map((item) =>
                                            //             DISIBILITY_TYPE.find((e) => item == e.value)
                                            //         )
                                            //         : []
                                            // }
                                            onChange={(event, newValue) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    solvedProblem: newValue.label,
                                                });
                                            }}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <li key={key} {...optionProps}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Repairing status*" placeholder="" />
                                            )}
                                        />
                                    </Box>}

                                {variant1 === SOLUTIONS.REPAIR && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                </Box>} */}