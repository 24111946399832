import { actions } from "../../utils/constants";


export const statusAction = (response) =>{
    return { type: actions.FETCH_DATA_STATUS_DATA , value : response}
}

export const statusActionFilters = (filters) =>{
    return { type: actions.FETCH_DATA_STATUS_Filters , filters : filters}
}

export const statusCountActionFilters = (filters) =>{
    return { type: actions.FETCH_DATA_COUNT_STATUS_Filters , Count : filters}
}

