
import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { PROBLEMS_LABLE, SNACK_BAR_VARIETNS, SOLUTIONS, STATUS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { CreateRepairingTicketApi, getRepairingTicketStatusByIdApi, RepairingTicketCreateApi, RepairingUpdateTicketApi } from "../../apis/repairing.api";
import axios from "axios";
import UpdateCreateQueryUi from "./UpdateCreateQueryUi";
import { statusActionFilters, statusCountActionFilters } from "../../store/actions/statusAction";
import { NewBoxIdForDmsUpdateTicketApi } from "../../apis/boxid.api";
import { FetchProblemApi } from "../../apis/problem.api";

const UpdateCreateQueryController = ({ fieldTicket, setTickets, id, statusPass }) => {

  const [fields, setFields] = useState({
    id: id,
    err: "",
    createdAt: fieldTicket?.createdAt ? fieldTicket?.createdAt : "",
    ticket_no: fieldTicket?.ticketNo ? fieldTicket?.ticketNo : "",
    product_id: '',
    box_id: "",
    customer_phone: "",
    customer_name: "",
    customer_email: "",
    source_id: "",
    trakingId: "",
    problems: [],
    repairingAmount: [],
    solved_in: "",
    checked: "",
    device_is: "",
    replace_remarks: "",
    customer_address: "",
    new_box_id: "",
    other:"",
    tracking_id: null,
    status: [{
      status_role: null,
      role: null
    }, {
      status_role: null,
      role: null
    }],
    repairing_amount_status: ""
  });
  const [variant1, setVariant] = useState();

  const updateTicketApi = RepairingTicketCreateApi;
  const [loading, setLoading] = useState(false);
  const validate = useValidate();
  const dispatch = useDispatch();
  const modalKey = 'Query';
  const title = "Query";
  const [arrr, setArrr] = useState([])

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.ticket_no,
        field: "name",
      },
      {
        required: true,
        value: fields.createdAt,
        field: "name",
      },
      {
        required: true,
        value: fields.product_id,
        field: "product_id",
      },
      {
        // required: true,
        value: fields.customer_email,
        field: "Email",
      },
      {
        required: true,
        value: fields.customer_address,
        field: "customer_address",
      },
      {
        // required: true,
        value: fields.box_id,
        field: "Box Id",
      },
  
      {
        required: true,
        value: fields.customer_phone,
        field: "Phone",
      },
      {
        required: true,
        value: fields.customer_name,
        field: "Customer Name",
      },
      {
        required: true,
        value: fields.source_id,
        field: "Source",
      },
      {
        required: true,
        value: fields.problems,
        field: "Problems",
      },
      {
        // required: true,
        value: fields.other,
        field: "other",
      },
      // {
      //   required: statusPass === STATUS.WAITING_FOR_PAYMENT && true,
      //   value: fields.repairing_amount_status,
      //   field: "repairing_amount_status",
      // },
      {
        required: (statusPass === STATUS.DISPATCH && fields.device_is === "Replace") && true,
        value: fields.new_box_id,
        field: "New Box Id",
      },{
        required:( statusPass === STATUS.DISPATCH ) && true,
        value: fields.tracking_id,
        field: "tracking id",
      }
    ],
    [fields]
  );
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['customer_name'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaForUpdate)
    if(statusPass === STATUS.REPLACEMENT){
    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await NewBoxIdForDmsUpdateTicketApi({ ...fields,amount: 0}),
        (response) => {
          // setFields("");
          setLoading(false)
          // setArrr([])
          // dispatch(closeModal("UpdateQuery"))
          // dispatch(statusActionFilters(filters));
          // dispatch(statusCountActionFilters(-1));
        },(err) => {
          console.log("error msg",err)
          setFields({ ...fields, err });
          setLoading(false);
        }
      ))
    } else {

      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  }


    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await RepairingUpdateTicketApi({ ...fields, product_id: fields.product_id?._id, source_id: fields.source_id?._id }),
        (response) => {
          setLoading(false)
          dispatch(callSnackBar("Updated successfully",SNACK_BAR_VARIETNS.suceess));
          dispatch(closeModal("UpdateQuery"))
          dispatch(statusActionFilters(filters));
          dispatch(statusCountActionFilters(-1));
        },
        setLoading(false)
      ))
    } else {
      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getRepairingTicketStatusByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });

          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);


  const [listProblem, setlistProblem] = useState()

  const fetchList = () => {
    // setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({ ...filters }),
        (response) => {
          setlistProblem(response?.result);
          // setLoading(false);
        },
        (err) => {
          // setLoading(false);
          console.log("error",err)
        }
      )
    );
  };

  useEffect(() => {
    fetchList()
  }, [filters])



  return (
    <UpdateCreateQueryUi
    listProblem={listProblem}
      modalKey={modalKey}
      title={title}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
      variant1={variant1}
      setVariant={setVariant}
      statusPass={statusPass}
    />
  );
};
export default memo(UpdateCreateQueryController);
