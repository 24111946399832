import React from 'react'
import SignInController from './pages/signin/SignInController'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Navigate } from 'react-router-dom'
import AppContainer from './components/layouts/common/AppContainer'
import PagenotFound from './components/layouts/PagenotFound'
import DashboardPageController from './pages/dashboard/DashboardPageController';
import { getDefaultRedirect } from './utils/routinghelper';
import NotAllowedComponent from './components/layouts/NotAllowedComponent';
import { USER_ROLES } from './utils/constants';
import DashboardPageControllerLD from './pages/Ld/DashboardPageControllerLD';
import DashboardPageControllerRepair from './pages/Repair/DashboardPageControllerRepair';
import { Construction, ContentPasteSearch, Groups2Outlined, LocalShipping, SupportAgent } from '@mui/icons-material';
import ListController from './pages/user/UserLIstController';
import ProblemListController from './pages/problem/ProblemListController';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const loggedInPathElementRender = (login, allowed = [], permittedModule = [], Component, defaultRedirect, hideInPannel = false) => {

  const obj = {
    hideInPannel,
    element: Component,
  }
  if (!login) {
    obj['element'] = <Navigate replace to={'/sign-in'} />
  } else {

    let found = false
    for (let module of allowed) {
      for (let allowedModule of permittedModule) {
        if (module == allowedModule) {
          found = true
          break;
        }
      }
    }
    if (!found) {
      obj['hideInPannel'] = true
      obj['element'] = <NotAllowedComponent />
    }
  }
  return obj
}


const defineRoutes = (user) => {
  const allowedModules = [user.data.role] ?? []
  const defaultRedirect = getDefaultRedirect(allowedModules)

  return ([
    {
      path: "sign-in",
      element: !user.isLoggedIn ?
        <SignInController />
        :
        (user.data.role === USER_ROLES.ADMIN ? 
        <Navigate replace to="/Admin_dashboard_crm" />
          :
          user.data.role === USER_ROLES.CRM_TEAM ? (
            <Navigate replace to="/dashboard" />
          ) : user.data.role === USER_ROLES.LD ? (
            <Navigate replace to="/ld_dashboard" />
          ) : user.data.role === USER_ROLES.REPAIR_TEAM ? (
            <Navigate replace to="/repair_dashboard" />
          ) : null
        ),
      hideInPannel: true

    },
    {
      path: "",
      element: user.isLoggedIn ? (
        user.data.role === USER_ROLES.ADMIN ? 
        <Navigate replace to="/Admin_dashboard_crm" />
          :
          user.data.role === USER_ROLES.CRM_TEAM ? (
            <Navigate replace to="/dashboard" />
          ) : user.data.role === USER_ROLES.LD ? (
            <Navigate replace to="/ld_dashboard" />
          ) : user.data.role === USER_ROLES.REPAIR_TEAM ? (
            <Navigate replace to="/repair_dashboard" />
          ) : null
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: true
    },
    {
      path: "Admin_dashboard_crm",
      icon: <SupportAgent />,
      title: "Crm Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN], allowedModules, <AppContainer ><DashboardPageController  /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    
    {
      path: "Admin_dashboard_ld",
      icon: <LocalShipping />,
      title: "Logistics Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN], allowedModules, <AppContainer ><DashboardPageControllerLD /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "Admin_dashboard_repair",
      icon: <Construction />,
      title: "Repair Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN], allowedModules, <AppContainer ><DashboardPageControllerRepair /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "problem",
      icon: <ContentPasteSearch />,
      title: "Problem",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN], allowedModules, <AppContainer ><ProblemListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },

    {
      path: "dashboard",
      icon: <SupportAgent />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.CRM_TEAM], allowedModules, <AppContainer ><DashboardPageController /></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN && true,
    },
    {
      path: "ld_dashboard",
      icon: <LocalShipping />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.LD], allowedModules, <AppContainer ><DashboardPageControllerLD /></AppContainer>, defaultRedirect),
      hideInPannel: user.data.role === USER_ROLES.ADMIN &&true,
    },
    {
      path: "repair_dashboard",
      icon: <Construction />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.REPAIR_TEAM], allowedModules, <AppContainer ><DashboardPageControllerRepair /></AppContainer>, defaultRedirect),
      hideInPannel:user.data.role === USER_ROLES.ADMIN && true,
    },
    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN], allowedModules, <AppContainer ><ListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,

    },

    {
      path: "*",
      hideInPannel: true,
      element: !user.isLoggedIn ? <Navigate replace to={"/sign-in"} /> : <PagenotFound />,
    },

  ])

}

export default defineRoutes