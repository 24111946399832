import { Badge, Box, Collapse, Fab, IconButton, Paper, SwipeableDrawer, styled, useMediaQuery, useTheme } from "@mui/material"
import { useSelector, useDispatch } from 'react-redux'
import { Link, Outlet, useLocation } from "react-router-dom"



import { useEffect, useMemo, useState } from "react"
import { memo } from "react"
import AppModeLabel from "../../texts/AppModeLabel"
import PopUpModal from "../../Modal"
import Logo from "./Logo"
import Header from "./Header"
import Navbar from "./Navbar"
import { USER_ROLES } from "../../../utils/constants"
// import  from "../../../pages/main/"




const containerStyle = (theme) => ({ height: "100%", width: "100%", display: "flex",overflow:"hidden", background: theme.palette.light.main })
const navBarOuterStyle = (theme) => ({ height: "100%", position: "relative" })
const collapsableContainerStyle = (theme) => ({ height: "100%", display: "flex", flexDirection: "column" })
const navbarInnerStyle = (theme) => ({
  width: "260px",
  background: "white",
  height: "100%",
  overflowY: "auto",
  borderRadius: "0px",
  scrollBarWidth: "none",
  " -ms-overflow-style": "none",
  "::-webkit-scrollbar": {
    display: 'none'
  }
})

const logoContainer = (theme) => ({ width: "100%", position: "sticky", top: "0px", height: "7vh",  zIndex: "1" })
const rightContainerStyle = (theme) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height:"100vh",
  overflow: "auto",
  scrollBarWidth: "none",
  })
const WorkAreaComponent = styled(Box)(({ theme, hideOverflow }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  flex: 1,
  overflow: hideOverflow ? "hidden" : "unset",
  background: theme.palette.grey.main,
  borderTopLeftRadius: theme.shape.borderRadius * 1
}))
const workAreaStyle = (theme) => ({


})


const AppContainer = ({ hideOverflow, ...props }) => {
  const user = useSelector(state => state.user)

  const [navBar, setNavbar] = useState(user.data.role === USER_ROLES.ADMIN)
  const location = useLocation()
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))


  return (
    <>
      {
        process.env.REACT_APP_APP_MODE != 'production' && <AppModeLabel />
      }

      <PopUpModal />

      <Box sx={containerStyle}>
        {!isSmallScreen && <Collapse orientation="horizontal" in={navBar} sx={collapsableContainerStyle}>

          <Paper elevation={0} sx={navbarInnerStyle}>
            <Box pt={3} mb={6} sx={logoContainer}>
              <Logo mb={4} />
            </Box>
            <Navbar />
          </Paper>

        </Collapse>
        }
        {
          isSmallScreen &&
          <SwipeableDrawer
            anchor={"left"}
            open={navBar}
            onClose={() => setNavbar(false)}
            onOpen={() => setNavbar(true)}
          >
            <Paper elevation={0} sx={navbarInnerStyle}>
              <Box pt={1} mb={5} sx={logoContainer}>
                <Logo mb={4} />
              </Box>
              <Navbar />
            </Paper>
          </SwipeableDrawer>
        }
        <Box sx={rightContainerStyle}>

          <Header open={navBar} setOpen={setNavbar} />
          <WorkAreaComponent p={3} hideOverflow={hideOverflow}>
            {props?.children}
            <Outlet />
          </WorkAreaComponent>
        </Box>
      </Box>

    </>

  )
}
export default memo(AppContainer)