
import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { PROBLEMS_LABLE, SNACK_BAR_VARIETNS, SOLUTIONS, STATUS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import CreateQueryUi from "./CreateQueryUi";
import { CreateRepairingTicketApi, getRepairingTicketStatusByIdApi, RepairingTicketCreateApi } from "../../apis/repairing.api";
import axios from "axios";
import { statusAction, statusActionFilters, statusCountActionFilters } from "../../store/actions/statusAction";
import { FetchProblemApi } from "../../apis/problem.api";

const CreateQueryController = ({ statusPass, fieldTicket, setTickets, id }) => {

  const [fields, setFields] = useState({
    _id: "",
    err: "",
    createdAt: fieldTicket?.createdAt ? fieldTicket?.createdAt : "",
    ticket_no: fieldTicket?.ticketNo ? fieldTicket?.ticketNo : "",
    product_id: "",
    box_id: "",
    customer_phone: "",
    customer_name: "",
    customer_email: "",
    source_id: "",
    trakingId: "",
    problems: [],
    repairingAmount: [],
    solved_in: "",
    customer_address: "",
    other: "",
    status: []
  });
  const [variant1, setVariant] = useState();

  const updateTicketApi = RepairingTicketCreateApi;
  const [loading, setLoading] = useState(false);
  const validate = useValidate();
  const dispatch = useDispatch();
  const modalKey = 'Query';
  const title = "Query";
  const [arrr, setArrr] = useState([])

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.ticket_no,
        field: "name",
      },
      {
        required: true,
        value: fields.createdAt,
        field: "name",
      },
      {
        required: true,
        value: fields.product_id,
        field: "product_id",
      },
      {
        // required: true,
        value: fields.customer_email,
        field: "Email",
      },
      {
        // required: true,
        value: fields.box_id,
        field: "Box Id",
      },
      {
        required: true,
        value: fields.customer_phone,
        field: "Phone",
      },
      {
        required: true,
        value: fields.customer_name,
        field: "Customer Name",
      },
      {
        required: true,
        value: fields.source_id,
        field: "Source",
      },
      {
        required: true,
        value: fields.problems,
        field: "Problems",
      },
      {
        // required: true,
        value: fields.other,
        field: "other",
      },
      {
        required: true,
        value: fields.solved_in,
        field: "solved_in",
      },
      {
        required: true,
        value: fields.customer_address,
        field: "customer_address",
      },
    ],
    [fields]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['cName'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });
  const statusData = useSelector((state) => state)

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaForUpdate)
    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await updateTicketApi({ ...fields }),
        (response) => {
          setFields("");
          setLoading(false)
          setArrr([])
          dispatch(callSnackBar("Query Created successfully", SNACK_BAR_VARIETNS.suceess));
          dispatch(closeModal("Query"))
          dispatch(statusActionFilters(filters));
          dispatch(statusCountActionFilters(-1));
        },
        setLoading(false)
      ))
    } else {
      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  };




  // console.log("first",fields.problems.length)
  const result = () => {

    fields.problems?.map((item) => {
      setArrr((prevSelected) => {
        if (prevSelected.includes(item)) {
          console.log("item",item)
          return prevSelected.filter((id) => id !== item);
        } else {
          return [...prevSelected, item];
        }
      });
    })
  }

  useEffect(() => {
    // setArrr([])
    result()
  }, [fields.problems])

  const [listProblem, setlistProblem] = useState()

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({ ...filters }),
        (response) => {
          setlistProblem(response?.result);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList()
  }, [filters])

  return (
    <CreateQueryUi
      setArrr={setArrr}
      arrr={arrr}
      listProblem={listProblem}
      // isRolePredefined={isRolePredefined}
      modalKey={modalKey}
      title={title}
      // isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
      variant1={variant1}
      setVariant={setVariant}
    />
  );
};
export default memo(CreateQueryController);
