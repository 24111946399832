import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, ListItem, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, STATUS, USER_ROLES, PAYMENT_METHOD, CANCEL_PAYMENT } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi } from "../../apis/boxid.api";


const UpdateCreateQueryUi = ({listProblem, statusPass, setArrr, arrr, title, modalKey, isUpdate, fields, setFields, loading, onSubmit, setVariant, variant1 }) => {
    const user = useSelector(state => state.user)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const selectedDispatch = useRef();
    return (
        <>
            <CustomDialog
                id={`UpdateQuery`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={` Update ${title}`}
                closeText="Close"
                confirmText={`Update`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    defaultVal={
                                        fields.product_id?._id ? {
                                            _id: fields.product_id?._id,
                                            name: fields.product_id?.name,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, product_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>
                            <CustomInput
                                // autoFocus={true}
                                disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                value={fields.box_id}
                                onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                type="text"
                                label={" Box Id.*"}
                            />
                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />

                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />
                            </Box>

                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}

                                    defaultVal={
                                        fields.source_id?._id ? {
                                            _id: fields.source_id?._id,
                                            title: fields.source_id?.title,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>


                                <Autocomplete
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={listProblem?.filter(problem => fields.problems.includes(problem.name)) || []}  
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []} 
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />

                                {fields?.problems?.map((item) =>
                                (item === "Other" && <CustomInput
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />
                                ))}
                            </Box>
                            {statusPass === STATUS.START_EVALUTION && <FormControl >
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setFields({
                                                        ...fields,
                                                        err: "",
                                                        status: [
                                                            // ...fields.status,
                                                            {
                                                                status_role: STATUS.EVALUTION,
                                                                role: USER_ROLES.CRM_TEAM
                                                            },
                                                            {
                                                                status_role: STATUS.EVALUTION,
                                                                role: USER_ROLES.LD
                                                            }
                                                        ]
                                                    });
                                                }}
                                            />
                                        }
                                        label="Evalution Start"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>}
                            {statusPass === STATUS.EVALUTION && <>
                                <Box mt={5}>
                                    <FormControl >
                                        <FormLabel component="legend">Conclusion</FormLabel>
                                        <FormGroup component="fieldset" row>


                                            {/* 
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={fields.conclusion === "physical_damage"}
                                                        onChange={(e) => {
                                                            setFields({
                                                                ...fields,
                                                                err: "",
                                                                conclusion: e.target.name,
                                                                status: [
                                                                    // ...fields.status,
                                                                    {
                                                                        status_role: STATUS.WAITING_FOR_PAYMENT,
                                                                        role: USER_ROLES.LD
                                                                    }, {
                                                                        status_role: STATUS.LOGISTIC_DEPARTMENT,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    }

                                                                ]
                                                            });
                                                        }}
                                                        name="physical_damage"
                                                    />
                                                }
                                                label="Physical Damage"
                                                labelPlacement="end"
                                            /> */}
                                            {statusPass === STATUS.EVALUTION && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={fields.conclusion === "send_for_repair"}
                                                        onChange={(e) => {
                                                            setFields({
                                                                ...fields,
                                                                err: "",
                                                                conclusion: e.target.name,
                                                                status: [
                                                                    // ...fields.status,
                                                                    {
                                                                        status_role: STATUS.DEVICE_RECEIVED,
                                                                        role: USER_ROLES.REPAIR_TEAM
                                                                    }
                                                                    , {
                                                                        status_role: STATUS.SEND_TO_REPAIR,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    }, {
                                                                        status_role: STATUS.SEND_TO_REPAIR,
                                                                        role: USER_ROLES.LD
                                                                    }
                                                                ]
                                                            });
                                                        }}
                                                        name="send_for_repair"
                                                    />
                                                }
                                                label="Send for Repair Team"
                                                labelPlacement="end"
                                            />}
                                        </FormGroup>
                                    </FormControl>
                                </Box>

                                {/* {fields.conclusion === "physical_damage" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        autoFocus={true}
                                        disabled={loading}
                                        value={fields?.physical_damage?.damage_part}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                                        type="text"
                                        label={"Damage Part*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={loading}
                                        value={fields?.physical_damage?.repairing_amount}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                                        type="text"
                                        label={"Cost*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={loading}
                                        value={fields?.physical_damage?.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                                        type="text"
                                        label={"Remarks*"}
                                    />

                                </Box>} */}

                                {/* {statusPass === STATUS.WAITING_FOR_PAYMENT && <Box mt={2}>
                                    <FormControl fullWidth>
                                        <InputLabel>Pending Payment</InputLabel>
                                        <Select
                                            value={fields.repairing_amount_status}
                                            label="Pending Payment"
                                            onChange={(e) => {
                                                const selectedStatus = e.target.value;
                                                const status = [
                                                    (fields.repairing_amount_status !== PAYMENT_METHOD.PAYMENT_RECEIVED
                                                        ?
                                                        {
                                                            status_role: STATUS.SEND_TO_REPAIR,
                                                            role: USER_ROLES.REPAIR_TEAM
                                                        }
                                                        :
                                                        {
                                                            status_role: STATUS.SHIPPED,
                                                            role: USER_ROLES.LD
                                                        })

                                                ];

                                                setFields({
                                                    ...fields,
                                                    err: '',
                                                    repairing_amount_status: selectedStatus,
                                                    status
                                                });
                                            }}
                                        >
                                            <MenuItem value={PAYMENT_METHOD.PAYMENT_RECEIVED}>{titleCase(PAYMENT_METHOD.PAYMENT_RECEIVED)}</MenuItem>
                                            <MenuItem value={PAYMENT_METHOD.CANCEL_PAYMENT}>{titleCase(PAYMENT_METHOD.CANCEL_PAYMENT)}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                } */}
                            </>}
                            {statusPass === STATUS.WAITING_FOR_PAYMENT && <Box mt={2}>
                                {fields.device_is === "physical_damage" && <Box mt={1} gap={3} mb={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}
                                        value={fields?.physical_damage?.damage_part}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                                        type="text"
                                        label={"Damage Part*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}
                                        value={fields?.physical_damage?.repairing_amount}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                                        type="text"
                                        label={"Cost*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}
                                        value={fields?.physical_damage?.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                                        type="text"
                                        label={"Remarks*"}
                                    />

                                </Box>}

                                <FormControl fullWidth>
                                    <InputLabel>Pending Payment</InputLabel>
                                    <Select
                                        value={fields.repairing_amount_status}
                                        label="Pending Payment"
                                        onChange={(e) => {
                                            setFields({
                                                ...fields,
                                                err: '',
                                                repairing_amount_status: e.target.value,
                                                // status
                                            });
                                        }}
                                    >
                                        <MenuItem value={PAYMENT_METHOD.PAYMENT_RECEIVED}>{titleCase(PAYMENT_METHOD.PAYMENT_RECEIVED)}</MenuItem>
                                        <MenuItem value={PAYMENT_METHOD.CANCEL_PAYMENT}>{"Unable To Pay"}</MenuItem>
                                    </Select>
                                </FormControl>

                                {fields.repairing_amount_status === PAYMENT_METHOD.PAYMENT_RECEIVED && <FormControl >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            status: [
                                                                {
                                                                    status_role: STATUS.SEND_TO_REPAIR,
                                                                    role: USER_ROLES.CRM_TEAM
                                                                },
                                                                {
                                                                    status_role: STATUS.SEND_TO_REPAIR,
                                                                    role: USER_ROLES.LD
                                                                },
                                                                {
                                                                    status_role: STATUS.PAYMENT_RECEIVED,
                                                                    role: USER_ROLES.REPAIR_TEAM
                                                                }
                                                            ]
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Sent To Repair"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>}
                                {fields.repairing_amount_status === PAYMENT_METHOD.CANCEL_PAYMENT && <>

                                    <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>

                                        <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                            <FormLabel id="demo-radio-buttons-group-label">Customer need Devices Back ? :</FormLabel>
                                            <RadioGroup
                                                row
                                                sx={{ marginLeft: "10px" }}
                                                value={selectedDispatch.current}
                                                onChange={(e) => {
                                                    const data = e.target.value

                                                    let statusdata = [];
                                                    if (data == CANCEL_PAYMENT.YES) {
                                                        statusdata = [
                                                            {
                                                                status_role: STATUS.DISPATCH,
                                                                role: USER_ROLES.CRM_TEAM,
                                                            },
                                                            {
                                                                status_role: STATUS.DISPATCH,
                                                                role: USER_ROLES.LD,
                                                            },
                                                        ];
                                                    }

                                                    if (data == CANCEL_PAYMENT.NO) {

                                                        statusdata = [
                                                            {
                                                                status_role: STATUS.NOT_DISPATCH,
                                                                role: USER_ROLES.CRM_TEAM,
                                                            },
                                                            {
                                                                status_role: STATUS.NOT_DISPATCH,
                                                                role: USER_ROLES.LD,
                                                            },
                                                            {
                                                                status_role: STATUS.NOT_DISPATCH,
                                                                role: USER_ROLES.REPAIR_TEAM,
                                                            },
                                                        ];
                                                    }

                                                    setFields({
                                                        ...fields,
                                                        err: "",
                                                        status: statusdata,
                                                    });
                                                }}
                                            >
                                                {Object.keys(CANCEL_PAYMENT).map((item) => (
                                                    <FormControlLabel
                                                        disabled={loading}
                                                        key={item}
                                                        value={CANCEL_PAYMENT[item]}
                                                        control={<Radio />}
                                                        label={titleCase(item)}
                                                    />
                                                ))}
                                            </RadioGroup>

                                        </FormControl>
                                    </Box>
                                    {selectedDispatch && (
                                        <CustomInput
                                            disabled={loading}
                                            value={fields.dispatch_remarks}
                                            onChange={(e) => setFields({ ...fields, err: '', dispatch_remarks: e.target.value })}
                                            type="text"
                                            label={"Remarks*"}
                                        />
                                    )}

                                </>}
                            </Box>
                            }
                        </Box>
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(UpdateCreateQueryUi);



// {fields.repairing_amount_status === PAYMENT_METHOD.CANCEL_PAYMENT && (
//     <>
//         <FormControl>
//             <FormGroup row>
//                 <FormControlLabel
//                     control={
//                         <Checkbox
//                             checked={selectedDispatch === 'dispatch'}
//                             onChange={(e) => {
//                                 setSelectedDispatch("dispatch");
//                                 setFields({
//                                     ...fields,
//                                     err: "",
//                                     status: [
//                                         {
//                                             status_role: STATUS.DISPATCH,
//                                             role: USER_ROLES.CRM_TEAM
//                                         },
//                                         {
//                                             status_role: STATUS.DISPATCH,
//                                             role: USER_ROLES.LD
//                                         }
//                                     ]
//                                 });
//                             }}
//                         />
//                     }
//                     label="Want To Dispatch"
//                     labelPlacement="end"
//                 />

//                 <FormControlLabel
//                     control={
//                         <Checkbox
//                             checked={selectedDispatch === 'notDispatch'}
//                             onChange={(e) => {
//                                 setSelectedDispatch('notDispatch');
//                                 setFields({
//                                     ...fields,
//                                     err: "",
//                                     status: [
//                                         {
//                                             status_role: STATUS.NOT_DISPATCH,
//                                             role: USER_ROLES.CRM_TEAM
//                                         },
//                                         {
//                                             status_role: STATUS.NOT_DISPATCH,
//                                             role: USER_ROLES.LD
//                                         },
//                                         {
//                                             status_role: STATUS.NOT_DISPATCH,
//                                             role: USER_ROLES.REPAIR_TEAM
//                                         }
//                                     ]
//                                 });
//                             }}
//                         />
//                     }
//                     label="Do Not Want To Dispatch"
//                     labelPlacement="end"
//                 />
//             </FormGroup>
//         </FormControl>

//         {selectedDispatch && (
//             <CustomInput
//                 disabled={loading}
//                 value={fields.dispatch_remarks}
//                 onChange={(e) => setFields({ ...fields, err: '', dispatch_remarks: e.target.value })}
//                 type="text"
//                 label={selectedDispatch === 'notDispatch' ? "Not Dispatch Remarks*" : "Dispatch Remarks*"}
//             />
//         )}
//     </>
// )}

{/* <Box mt={4} width={"100%"}>
                                <Box>
                                    <Typography variant="h3">
                                        Solved In :
                                    </Typography>
                                </Box>
                                <Box mt={4} gap={2} display={"flex"} justifyContent={"space-around"} flexDirection={"row"} >
                                    <Box>
                                        <Button
                                            variant={fields.solved_in === "call" || variant1 === SOLUTIONS.CALL ? "contained" : "outlined"}
                                            onClick={(e) => {
                                                setVariant(SOLUTIONS.CALL);
                                                setFields({ ...fields, err: '', solved_in: SOLUTIONS.CALL })
                                            }
                                            }
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.Videao_Call);
                                            setFields({ ...fields, err: '', solved_in: SOLUTIONS.Videao_Call })
                                        }}
                                            variant={fields.solved_in === "vc" || variant1 === SOLUTIONS.Videao_Call ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Video Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.REPAIR);
                                            setFields({ ...fields, err: '', solved_in: SOLUTIONS.REPAIR })
                                        }}
                                            variant={fields.solved_in === "repairing" || variant1 === SOLUTIONS.REPAIR ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Repair</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box> */}

{/* {variant1 === SOLUTIONS.REPAIR && <PaddingBoxInDesktop mt={4} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                    <AsyncDropDown
                                        // lazyFun={async (para) => await getTicketProblemsFetchApi({ ...para, allStatus: true })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.name}</ListItem >
                                        }}
                                        defaultVal={
                                            fields.status ? {
                                                _id: fields.status._id,
                                                name: fields.status.name,
                                            } : null
                                        }
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, status: changedVal ? changedVal._id : null, })
                                        }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder={"Repairing Ticket Problems*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}
                                {variant1 === SOLUTIONS.REPAIR &&
                                    <Box mt={3}>
                                        <Autocomplete

                                            options={SOLVED_STATUS}
                                            // defaultValue={
                                            //     fields.disibility_type
                                            //         ? fields.disibility_type?.map((item) =>
                                            //             DISIBILITY_TYPE.find((e) => item == e.value)
                                            //         )
                                            //         : []
                                            // }
                                            onChange={(event, newValue) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    solvedProblem: newValue.label,
                                                });
                                            }}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <li key={key} {...optionProps}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Repairing status*" placeholder="" />
                                            )}
                                        />
                                    </Box>}

                                {variant1 === SOLUTIONS.REPAIR && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                </Box>} */}