import { Box, Button, ButtonGroup, Grid, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { FilterTitleBox, FiltersBox } from '../../components/layouts/OneViewBox';
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';

import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import { BUTTON_TYPE, STATUS } from '../../utils/constants';
import ButtonComponentsUi from './ButtonComponentsUi';
import { titleCase } from '../../utils/main';

const DashboardPageUI = ({ Count, CountStatus, setSTATUSWiseData, STATUSWiseData, filters, setFilters, createTiket, setList, loading, list, columns, setLDstatusButton, LDstatusButton }) => {

  return (
    <>
      <Box  >
        <Paper sx={{ width: "100%", height: "85vh", padding: 6, overflow: "hidden" }}>
          <Box   >
            <FilterTitleBox >
              <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                <Box >
                  <Typography variant="h3" color={"primary"}>
                    Logistics Department Dashboard
                  </Typography>
                </Box>

              </Box>
            </FilterTitleBox>
          </Box>

          <Grid mt={4} md={12} xs={12} sm={12} container>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Grid md={2.9} sm={2.8} xs={2.8}>
                <ButtonComponentsUi onSubmit={() => { setLDstatusButton(BUTTON_TYPE.pending); setSTATUSWiseData(STATUS.YET_TO_RECEIVED) }} 
                count={((CountStatus?.response[0]?.count ?? 0) + (CountStatus?.response[2]?.count ?? 0)  ?? 0)} STATUSTypeData={BUTTON_TYPE.pending} colorType={LDstatusButton == BUTTON_TYPE.pending} />
              </Grid>
              <Grid md={2.9} sm={2.8} xs={2.8}>

                <ButtonComponentsUi onSubmit={() => { setLDstatusButton(BUTTON_TYPE.Work_In_Progress); setSTATUSWiseData(STATUS.EVALUTION) }} 
                count={((CountStatus?.response[1]?.count ?? 0) + (CountStatus?.response[4]?.count ?? 0)  + (CountStatus?.response[5]?.count ?? 0)?? 0)} STATUSTypeData={BUTTON_TYPE.Work_In_Progress} colorType={LDstatusButton == BUTTON_TYPE.Work_In_Progress} />
              </Grid>
              <Grid md={2.9} sm={2.8} xs={2.8}>

                <ButtonComponentsUi onSubmit={() => { setLDstatusButton(BUTTON_TYPE.Solved); setSTATUSWiseData(STATUS.DISPATCH) }}
                 count={((CountStatus?.response[7]?.count ?? 0) +  (CountStatus?.response[6]?.count ?? 0)  + (CountStatus?.response[9]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.Solved} colorType={LDstatusButton == BUTTON_TYPE.Solved} />
              </Grid>
              <Grid md={2.9} sm={2.8} xs={2.8}>
                <ButtonComponentsUi
                  count={((CountStatus?.response[0]?.count ?? 0) + (CountStatus?.response[2]?.count ?? 0) +
                    (CountStatus?.response[1]?.count ?? 0) + (CountStatus?.response[4]?.count ?? 0)  + (CountStatus?.response[5]?.count ?? 0) + (CountStatus?.response[7]?.count ?? 0) +  (CountStatus?.response[6]?.count ?? 0)   + (CountStatus?.response[9]?.count ?? 0) ?? 0)}
                  Total={"Total"}
                  STATUSTypeData
                />
              </Grid>
            </Box>
          </Grid>

          <Box mt={4} sx={{ display: "flex", justifyContent: "space-between", Width: "100%" }}>

            {LDstatusButton === BUTTON_TYPE.pending && <Box my={3}> <ButtonGroup >
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.YET_TO_RECEIVED)} count={CountStatus?.response[0]?.count ?? 0} Title={STATUS.YET_TO_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.YET_TO_RECEIVED} />
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.SEND_TO_CRM)} count={CountStatus?.response[2]?.count ?? 0} Title={STATUS.SEND_TO_CRM} STATUSWiseData={STATUSWiseData == STATUS.SEND_TO_CRM} />
            </ButtonGroup>
            </Box>}

            {LDstatusButton === BUTTON_TYPE.Work_In_Progress && <Box my={3}> <ButtonGroup >
            <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.EVALUTION)} count={CountStatus?.response[1]?.count ?? 0} Title={STATUS.EVALUTION} STATUSWiseData={STATUSWiseData == STATUS.EVALUTION} />
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.SEND_TO_REPAIR)} count={CountStatus?.response[4]?.count ?? 0} Title={STATUS.SEND_TO_REPAIR} STATUSWiseData={STATUSWiseData == STATUS.SEND_TO_REPAIR} />
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.WAITING_FOR_PAYMENT)} count={CountStatus?.response[5]?.count ?? 0} Title={STATUS.WAITING_FOR_PAYMENT} STATUSWiseData={STATUSWiseData == STATUS.WAITING_FOR_PAYMENT} />
              {/* <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPAIR_COMPLETE)} count={CountStatus?.response[14]?.count ?? 0} Title={STATUS.REPAIR_COMPLETE} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_COMPLETE} /> */}
              {/* <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPAIR_REPLACEMENT)} count={CountStatus?.response[15]?.count} Title={STATUS.REPAIR_REPLACEMENT} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_REPLACEMENT} /> */}
              </ButtonGroup>  </Box>}

            {LDstatusButton === BUTTON_TYPE.Solved && <Box my={3}> <ButtonGroup >
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.DISPATCH)} count={CountStatus?.response[7]?.count ?? 0} Title={STATUS.DISPATCH} STATUSWiseData={STATUSWiseData == STATUS.DISPATCH} />
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.NOT_DISPATCH)} count={CountStatus?.response[6]?.count ?? 0} Title={STATUS.NOT_DISPATCH} STATUSWiseData={STATUSWiseData == STATUS.NOT_DISPATCH} />
              <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.COMPLETED)} count={CountStatus?.response[9]?.count ?? 0} Title={STATUS.COMPLETED} STATUSWiseData={STATUSWiseData == STATUS.COMPLETED} />

            </ButtonGroup>
            </Box>}

            <PaddingBoxInDesktop
              my={3}
              sx={{ display: "flex", width: "20%" }}
              pl={3}>
              <AsyncSearchBar
                fullWidth
                title="Search By Name "
                size="small"
                placeholder={"Search By Name"}
                defaultValue={filters.search}
                onChange={(changedVal) => {
                  setFilters({ ...filters, search: changedVal });
                }}
              />
            </PaddingBoxInDesktop>
          </Box>


          <Box overflow={"auto"} height={"60vh"}>
            <DataTable customRows={true} columns={columns} rows={list?.result ? list?.result : []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
          </Box>
        </Paper >
      </Box >


    </>
  )
}

export default DashboardPageUI