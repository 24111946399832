import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const getBoxIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.BoxIdFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};


export const getBoxnotallocatedboxIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.notallocatedboxidFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const NewBoxIdForDmsUpdateTicketApi = async data => {
    const callResponse = await axios({
      url: endpoints.changeboxIdfordms,
      method: "patch",
      headers: getHeaders(),
      data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  