import { Box, Button, ButtonGroup, Grid, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { FilterTitleBox, FiltersBox } from '../../components/layouts/OneViewBox';
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';

import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import { BUTTON_TYPE, STATUS } from '../../utils/constants';
import ButtonComponentsUi from './ButtonComponentsUi';
import { titleCase } from '../../utils/main';

const DashboardPageUI = ({ Count, CountStatus, setSTATUSWiseData, STATUSWiseData, filters, setFilters, createTiket, setList, loading, list, columns, setRepairstatusButton, RepairstatusButton }) => {
  const dispatch = useDispatch();

  return (
    <>
      <Box  >
        <Paper sx={{ width: "100%", height: "85vh", padding: 6, overflow: "hidden" }}>
          <Box   >
            <FilterTitleBox >
              <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                <Box >
                  <Typography variant="h3" color={"primary"}>
                    Repair Dashboard
                  </Typography>
                </Box>

              </Box>
            </FilterTitleBox>
          </Box>

          <Grid mt={4} md={12} xs={12} sm={12} container>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Grid md={3.9} sm={3.9} xs={3.9}>
                <ButtonComponentsUi onSubmit={() => { setRepairstatusButton(BUTTON_TYPE.Work_In_Progress); setSTATUSWiseData(STATUS.DEVICE_RECEIVED) }}
                 count={((CountStatus?.response[12]?.count ?? 0) + (CountStatus?.response[8]?.count ?? 0) + (CountStatus?.response[13]?.count ?? 0) + (CountStatus?.response[5]?.count ?? 0)??0)} STATUSTypeData={BUTTON_TYPE.Work_In_Progress} colorType={RepairstatusButton == BUTTON_TYPE.Work_In_Progress} />
              </Grid>
              <Grid md={3.9} sm={3.9} xs={3.9}>
                <ButtonComponentsUi onSubmit={() => { setRepairstatusButton(BUTTON_TYPE.Solved); setSTATUSWiseData(STATUS.REPAIR_COMPLETED) }} 
                count={((CountStatus?.response[10]?.count ?? 0) + (CountStatus?.response[11]?.count ?? 0) + (CountStatus?.response[6]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.Solved} colorType={RepairstatusButton == BUTTON_TYPE.Solved} />
              </Grid>
              <Grid md={3.9} sm={3.9} xs={3.9}>
                <ButtonComponentsUi
                  count={((CountStatus?.response[12]?.count ?? 0) + (CountStatus?.response[8]?.count ?? 0) + (CountStatus?.response[13]?.count ?? 0) + (CountStatus?.response[5]?.count ?? 0) + (CountStatus?.response[10]?.count ?? 0) + (CountStatus?.response[11]?.count ?? 0) + (CountStatus?.response[6]?.count ?? 0) ?? 0)}
                  Total={"Total"}
                  STATUSTypeData
                />
              </Grid>
            </Box>
          </Grid>

          <Box mt={4} sx={{ display: "flex", justifyContent: "space-between", Width: "100%" }} >

            {RepairstatusButton === BUTTON_TYPE.Work_In_Progress && <Box my={3}>
              <ButtonGroup >
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.DEVICE_RECEIVED)} count={CountStatus?.response[12]?.count ?? 0} Title={STATUS.DEVICE_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.DEVICE_RECEIVED} />
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPAIR_START)} count={CountStatus?.response[8]?.count ?? 0} Title={STATUS.REPAIR_START} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_START} />
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.WAITING_FOR_PAYMENT)} count={CountStatus?.response[5]?.count ?? 0} Title={STATUS.WAITING_FOR_PAYMENT} STATUSWiseData={STATUSWiseData == STATUS.WAITING_FOR_PAYMENT} />
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.PAYMENT_RECEIVED)} count={CountStatus?.response[13]?.count ?? 0} Title={STATUS.PAYMENT_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.PAYMENT_RECEIVED} />
              </ButtonGroup>  
               </Box>}


            {RepairstatusButton === BUTTON_TYPE.Solved && <Box my={3}>
              <ButtonGroup >
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPAIR_COMPLETED)} count={CountStatus?.response[10]?.count ?? 0} Title={STATUS.REPAIR_COMPLETED} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_COMPLETED} />
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPLACEMENT)} count={CountStatus?.response[11]?.count ?? 0} Title={STATUS.REPLACEMENT} STATUSWiseData={STATUSWiseData == STATUS.REPLACEMENT} />
                <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.NOT_DISPATCH)} count={CountStatus?.response[6]?.count ?? 0} Title={STATUS.NOT_DISPATCH} STATUSWiseData={STATUSWiseData == STATUS.NOT_DISPATCH} />
              </ButtonGroup>
            </Box>}
            <PaddingBoxInDesktop
              my={3}
              sx={{ display: "flex", width: "20%" }}
              pl={3}>
              <AsyncSearchBar
                fullWidth
                title="Search By Name "
                size="small"
                placeholder={"Search By Name"}
                defaultValue={filters.search}
                onChange={(changedVal) => {
                  setFilters({ ...filters, search: changedVal });
                }}
              />
            </PaddingBoxInDesktop>
          </Box>

          <Box overflow={"auto"} height={"60vh"} >
            <DataTable customRows={true} columns={columns} rows={list?.result ? list?.result : []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
          </Box>
        </Paper >
      </Box >


    </>
  )
}

export default DashboardPageUI