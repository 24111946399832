import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import CustomDialog from '../../components/layouts/common/CustomDialog'
import moment from 'moment'
import { SOLUTIONS, STATUS, USER_ROLES } from '../../utils/constants'
import { findObjectKeyByValue, titleCase, toTitleCase } from '../../utils/main'

const CustomerInformation = ({ params }) => {

    return (
        <>
            <CustomDialog
                id={"CustomerInformation"}
                title={`Customer Information`}
            >
                <Box sx={{ height: "27vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                    <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                        <Typography variant='h4' color={"#1D013B"}>Customer Details :</Typography>
                    </Box>
                    <Grid container p={3} md={12}>

                        <Grid item mt={3} md={3} xs={3} sm={3} >
                            <Typography fontWeight="bold" variant="h6">Customer Name: </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params.customer_name ? params?.customer_name : "NA"}
                            </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3} >
                            <Typography fontWeight="bold" variant="h6">Query Created Date: </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {moment(params?.createdAt).format("DD/MM/YYYY")}
                            </Typography>
                        </Grid>

                        {params?.ticket_no && <><Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Customer Ticket No : </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.ticket_no ? params?.ticket_no : "NA"}
                                </Typography>
                            </Grid></>
                        }

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Customer Phone Number : </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params?.customer_phone ? params?.customer_phone : "NA"}
                            </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3} >
                            <Typography fontWeight="bold" variant="h6">Customer Email: </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params?.customer_email ? params?.customer_email : "NA"}
                            </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3} >
                            <Typography fontWeight="bold" variant="h6">Customer Source: </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params?.source_id?.title ? params?.source_id?.title : "NA"}
                            </Typography>
                        </Grid>

                    </Grid>

                </Box>


                <Box mt={2} sx={{ height: "27vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                    <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                        <Typography variant='h4' color={"#1D013B"}>Product Problem Details :</Typography>
                    </Box>
                    <Grid container p={3} md={12}>

                        <Grid item mt={3} md={3} xs={3} sm={3} >
                            <Typography fontWeight="bold" variant="h6">Product Name: </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params?.product_id?.name ? params?.product_id?.name : "NA"}
                            </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Device BoxId : </Typography>
                        </Grid>

                        <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params?.box_id ? params?.box_id : "NA"}
                            </Typography>
                        </Grid>



                        <Grid item mt={4} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Customer problems : </Typography>
                        </Grid>

                        <Grid item mt={4} md={9} xs={9} sm={9}>
                            <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                {params?.problems.map((item) =>
                                    <Typography variant='h6' sx={{ border: "1px solid grey", borderRadius: "5px", backgroundColor: "rgba(29, 1, 59, 0.1)", padding: "5px", marginRight: 2, }}>
                                        {item}
                                    </Typography>)}
                            </Typography>
                        </Grid>


                        {params?.other && <><Grid item mt={4} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Customer problems Other: </Typography>
                        </Grid>

                            <Grid item mt={4} md={9} xs={9} sm={9}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.other ? params?.other : "NA"}
                                </Typography>
                            </Grid></>}
                    </Grid>
                </Box>

                <Box mt={2} sx={{ height: "50vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                    <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                        <Typography variant='h4' color={"#1D013B"}>Repair & Replacement :</Typography>
                    </Box>
                    <Grid container p={3} md={12}>

                        {params?.conclusion && <><Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6"> Device repairing status: </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.conclusion ? titleCase(params?.conclusion) : "NA"}
                                </Typography>
                            </Grid></>}
                        {params?.tracking_id && <><Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6"> Device Tracking Id: </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.tracking_id ? titleCase(params?.tracking_id) : "NA"}
                                </Typography>
                            </Grid></>}

                        {params?.solved_in && <><Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Query Solved In : </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.solved_in ?
                                        toTitleCase(findObjectKeyByValue(params?.solved_in, SOLUTIONS))
                                        : "NA"}
                                </Typography>
                            </Grid></>}

                        {params?.device_is && <><Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Device type: </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.device_is ? params?.device_is : "NA"}
                                </Typography>
                            </Grid></>}

                        {params?.physical_damage?.remarks && <><Grid item mt={3} md={3} xs={3} sm={3} >
                            <Typography fontWeight="bold" variant="h6">Damage Remarks:</Typography>
                        </Grid>

                            <Grid container mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", color: "#808080", flexDirection: "column" }}>
                                    {params?.physical_damage?.remarks ? params?.physical_damage?.remarks : "NA"}

                                    {/* {params?.logs !== 0 ? params?.logs.map((e,index) => <Box sx={{ display: 'flex' }}>{ "("+ ++index + ")"} {e.remarks} </Box>) : "NA"} */}
                                </Typography>
                            </Grid></>}
                        {
                            params?.physical_damage?.damage_part && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Damage part: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.physical_damage?.damage_part ? params?.physical_damage?.damage_part : "NA"}
                                    </Typography>
                                </Grid></>}
                        {
                            params?.problem_solve_by_crm?.type_of_problem && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Type of problem solved by CRM: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.problem_solve_by_crm?.type_of_problem ? params?.problem_solve_by_crm?.type_of_problem : "NA"}
                                    </Typography>
                                </Grid></>}
                        {
                            params?.device_is && <>     <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Problem solved by CRM Remark : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.device_is ? params?.device_is : "NA"}
                                    </Typography>
                                </Grid></>}

                        {params?.repairable_part && <><Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Repairable Part: </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.repairable_part ? params?.repairable_part : "NA"}
                                </Typography>
                            </Grid></>}
                        {
                            params?.repairable_remark && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Repairable Remark : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.repairable_remark ? params?.repairable_remark : "NA"}
                                    </Typography>
                                </Grid>
                            </>}
                        {
                            params?.repairable_amount_status && <>  <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Repairable amount status: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.repairable_amount_status ? params?.repairable_amount_status : "NA"}
                                    </Typography>
                                </Grid></>}

                        {params?.replace_remarks && <>  <Grid item mt={3} md={3} xs={3} sm={3}>
                            <Typography fontWeight="bold" variant="h6">Replace Remark : </Typography>
                        </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.replace_remarks ? params?.replace_remarks : "NA"}
                                </Typography>
                            </Grid></>
                        }
                    </Grid>
                </Box>

                {/* <Box mt={2} sx={{ height: "27vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                    <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                        <Typography variant='h4' color={"#1D013B"}>Repairing Status :</Typography>
                    </Box>

                    <Grid container p={3} md={12}>
                        {params.status?.map((e) =>
                            <Grid container md={12}>
                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography fontWeight="bold" variant="h6"> status: </Typography>
                                </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {toTitleCase(findObjectKeyByValue(e.role, USER_ROLES))}
                                    </Typography>
                                </Grid>
                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography fontWeight="bold" variant="h6">Device track: </Typography>
                                </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {toTitleCase(findObjectKeyByValue(e.status_role, STATUS))}
                                    </Typography>
                                </Grid>
                            </Grid>)
                        }
                    </Grid>
                </Box> */}
            </CustomDialog>
        </>
    )
}

export default CustomerInformation


{/* <Grid item mt={3} md={3} xs={3} sm={3} >
<Typography fontWeight="bold" variant="h4">Repairing status:- </Typography>
</Grid>
{params.status?.map((e) =>
<Grid container md={12}>
    <Grid item mt={3} md={3} xs={3} sm={3}>
        <Typography fontWeight="bold" variant="h6"> status: </Typography>
    </Grid>

    <Grid item mt={3} md={3} xs={3} sm={3}>
        <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
            {toTitleCase(findObjectKeyByValue(e.role, USER_ROLES))}
        </Typography>
    </Grid>
    <Grid item mt={3} md={3} xs={3} sm={3}>
        <Typography fontWeight="bold" variant="h6">Device track: </Typography>
    </Grid>

    <Grid item mt={3} md={3} xs={3} sm={3}>
        <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
            {toTitleCase(findObjectKeyByValue(e.status_role, STATUS))}
        </Typography>
    </Grid>
</Grid>)
} */}