import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const CreateProblemApi = async data => {
    const callResponse = await axios({
        url: endpoints.CreateProblem,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};



export const FetchProblemApi = async params => {
    const callResponse = await axios({
        url: endpoints.FetchProblem,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const DeleteProblemApi = async data => {
    const callResponse = await axios({
        url: endpoints.DeleteProblem,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const UpdateProblemApi = async data => {
    const callResponse = await axios({
        url: endpoints.UpdateProblem,
        method: "patch",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const ProblemFindByIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.ProblemById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};