import * as React from 'react';
import gradLeft from '../../../assets/images/auth-left.png';
import gradRight from '../../../assets/images/auth-right.png';
import ImageComponent from '../../ImageComponent';
import { Box } from '@mui/material';

const BgGradient = ({ className, ...props }) => {
    if (props.left)
        return <Box sx={{
            position: 'absolute',
            top: 0,
            zIndex: -1,
            left: 0,
            height: '100vh',
            width: '48.438vw',
        }}>
            <ImageComponent src={gradLeft} sx={{
                height: "100%",
                width: "100%"
            }} alt="" />
        </Box>

    return <Box sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: -1,
        height: '100vh',
        width: '25.969vw',
    }}>
        <ImageComponent src={gradRight} sx={{
            height: "100%",
            width: "100%"
        }} alt="" />
    </Box>
}

export default BgGradient